import React from "react";

import { EditVirtualTourView, processServerErrors } from "./view";
import { EditFacilityDialog } from "@pages/DeepDivePanel/EditFacilityDialog";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

const EditVirtualTour: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <EditFacilityDialog
      name="EditVirtualTour"
      title="Virtual school tour"
      viewPath={`/map/${facility.id}/virtual-tour`}
      processErrors={processServerErrors}
    >
      {(view, setView) => (
        <EditVirtualTourView facilityView={view} setFacilityView={setView} />
      )}
    </EditFacilityDialog>
  );
};

export default EditVirtualTour;
